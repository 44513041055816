import React, { useState, useEffect } from "react";
import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// Create an axios instance with the base URL and credentials
const api = axios.create({
  baseURL: "https://bntdev-backend.closeloop.dev",
  withCredentials: true,
  withXSRFToken: true,
});

// Function to set the CSRF token
const setCSRFToken = async () => {
  try {
    const response = await api.get("/api/csrf-cookie");
    const csrfToken = response.headers["x-csrf-token"];

    if (csrfToken) {
      api.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    }
  } catch (error) {
    console.error("Error setting CSRF token:", error);
  }
};

// Axios interceptor to set the CSRF token for post, put, and delete requests
api.interceptors.request.use(
  (config) => {
    if (
      config.method === "post" ||
      config.method === "put" ||
      config.method === "delete"
    ) {
      config.headers["X-CSRF-TOKEN"] =
        api.defaults.headers.common["X-CSRF-TOKEN"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch CSRF token on component mount
  useEffect(() => {
    setCSRFToken();
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    api
      .post("/api/user/login", {
        email: "company_admin@yopmail.com",
        password: "Test@1234",
      })
      .then((response) => {
        setLoading(false);
        // Handle successful response
        console.log(response.data);
        console.log("getting there");
        initializeEcho();
        // For example, store the token or redirect the user
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response ? error.response.data.message : error.message);
      });
  };

  const meApia = () => {
    api
      .get("/api/user/me")
      .then((response) => {
        console.log("I am here");
        //  initializeEcho();
      })
      .catch((error) => {
        console.log(error);
        console.log("I am error");
      });
  };
  const initializeEcho = () => {
    window.Echo = new Echo({
      broadcaster: "reverb",
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {},
        };
      },
      key: "7c5x1rezroasmo3hfraq",
      wsHost: "bntdev-socket.closeloop.dev",
      wsPort: 8080,
      wssPort: 443,    
      forceTLS: ("http" ?? "https") === "https",
      enabledTransports: ["ws","wss"],
    });
    window.Echo.channel("dashboard").listen("OrderShipped", (e) => {
      console.log("here", e);
    });
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label>
            Username:
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <button onClick={meApia}>Initialize Echo</button>
    </div>
  );
}

export default Login;
